@tailwind base;
@tailwind components;
@tailwind utilities;

#app,
#root,
body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

@layer base {
  p {
    font-size: 1.075rem;
  }
}
